import { useAppStore } from '@/lib/stores/app';
import { ButtonLink, createComponent, For, If, IntrinsicProps } from 'react-commons';

import style from './index.module.scss';

interface GenreButtonsProps extends IntrinsicProps {
  downloadGenres?: boolean
}

export default createComponent<GenreButtonsProps>('GenreButtons', { style }, function GenreButtons ({ className }, props) {
  const [ appState ] = useAppStore();

  const genres = props.downloadGenres 
    ? appState.taxonomies.downloadGenres 
    : appState.taxonomies.onlineGenres;
  
  return (
    <div className={className}>
      {
        If(genres, () => (
          <>
            {
              For(genres, (genre, index) => (
                <ButtonLink 
                  key={`${genre.uid}_${index}`}
                  href={genre.href} 
                  secondary 
                  small
                >
                  {genre.name} Games
                </ButtonLink>
              ))
            }
          </>
        )).EndIf()
      }
    </div>
  );
});
