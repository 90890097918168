import React from 'react';
import { captureEvent } from '@/lib/util/captureEvent';
import { createContext, useContext, useEffect } from 'react';

interface CaptureClickEventContextState {
  page: string
  settings: any
}

export const CaptureClickEventContext = createContext<CaptureClickEventContextState>({
  page: '',
  settings: {},
});

interface CaptureClickEventProps {
  children: React.ReactNode
  action: string
  location: string
  className?: string
  href?: string
  disabled?: boolean | ((target: HTMLElement, href: string) => boolean)
  properties?: () => any
}

export function CaptureClickEvent (props: CaptureClickEventProps) {
  const context = useContext(CaptureClickEventContext);

  useEffect(() => {
    const handler = (e: MouseEvent) => {
      const data = props.properties 
        ? props.properties() 
        : {};
      
      const targetEl = e.target as HTMLElement;
      const actionClassName = `.${props.className || data?.tag_name || props.action}`;
      const actionEl = document.querySelector(actionClassName);

      if (!actionEl) {
        if (process.env.NODE_ENV === 'development') {
          throw new Error(`Could not find action element with class '${actionClassName}'.`);
        } else {
          return;
        }
      }

      if (!actionEl.contains(targetEl)) {
        return;
      }

      const targetUrl = (
        props.href || 
        actionEl.getAttribute('href') || 
        actionEl.querySelector('a')?.getAttribute('href') || 
        ''
      );

      if (typeof props.disabled === 'function' && props.disabled(targetEl, targetUrl)) {
        return;
      }
      if (typeof props.disabled === 'boolean' && props.disabled) {
        return;
      }

      const additionalProperties = props.properties 
        ? props.properties() 
        : {};

      const properties = {
        page: context.page,
        page_location: props.location,
        target_url: targetUrl,
        ...additionalProperties,
      };

      captureEvent(props.action, properties);
    };

    document.body.addEventListener('click', handler);

    return () => {
      document.body.removeEventListener('click', handler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });

  return (
    <>{props.children}</>
  );
}
