import { createComponent, IntrinsicProps, Slot } from 'react-commons';
import Pod from '@/components/Pod';
import GenreButtons from '@/components/GenreButtons';

interface GenresPodProps extends IntrinsicProps {
  downloadGenres?: boolean
}

export default createComponent<GenresPodProps>('GenresPod', {}, function GenresPod ({}, props) {
  return (
    <Pod gutter='none'>
      <Slot name='title'>Game Genres</Slot>
      <GenreButtons downloadGenres={props.downloadGenres} />
    </Pod>
  );
});
