import { ButtonLink, createComponent, Flex, IconFA, If, IntrinsicProps, Slot } from 'react-commons';
import Pod from '@/components/Pod';
import DailyArchive from '@/components/DailyArchive';

import style from './index.module.scss';
import Link from 'next/link';
import { useDailyArchive } from '@/lib/hooks/useDailyArchive';
import SwPaginator from '@/components/SwPaginator';
import { useAuthStore } from '@/lib/drupal/stores/auth';
import { FullGameData } from '@/lib/drupal/models/Games';
import { faCircleRight } from '@fortawesome/free-solid-svg-icons';
import { CaptureClickEvent } from '@/lib/util/captureClickEvent';

interface DailyArchivePodProps extends IntrinsicProps {
  game: FullGameData
  hasPagination?: boolean
  h1?: boolean
  isFullArchive?: boolean
  kind?: 'daily' | 'bonus'
}

export default createComponent<DailyArchivePodProps>('DailyArchivePod', { style }, function DailyArchivePod ({ slots, className }, props) {
  const [ authState ] = useAuthStore();
  
  const {
    rows,
    page,
    numPages,
    changePage
  } = useDailyArchive({
    archive: props.game.archive,
    useShortFormat: props.game.pageLayout !== 'jigsaw',
    isFullArchive: props.isFullArchive,
  });

  return (
    <Pod gutter='none' 
      className={className} 
      centerFooter 
      h1={props.h1}
    >
      <Slot name='title'>{slots.title}</Slot>
      <Slot name='toolbar'>
        {
          If(props.isFullArchive, () => (
            <img
              src='/images/shockwaveUnlimitedDark.svg'
              alt='Shockwave Unlimited'
              width={56}
              height={19}
              style={{ width: '56px', height: '19px' }}
            />
          )).Else(() => (
            <>
              {
                If(authState.ready && authState.user?.isPremiumUser, () => (
                  <ButtonLink 
                    href={props.game.href + '/archive'} 
                    secondary
                    small
                    className='--iconRight'
                  >
                    View Full Archive
                    <IconFA icon={faCircleRight} />
                  </ButtonLink>
                )).Else(() => (
                  <>
                    <p>
                      <CaptureClickEvent
                        action='upsell'
                        location='daily_archive_images'
                        href='/unlimited'
                        properties={() => ({
                          tag_name: 'upsell_archive_banner',
                          id: props.game.uid,
                          title: props.game.title,
                          view_node: props.game.href,
                        })}
                      >
                        <Link href='/unlimited'>
                          <a className='Flex Flex--alignCenter upsell_archive_banner'>
                            All {props.game.pageLayout === 'jigsaw' ? 'puzzles' : 'daily challenges'} available to&nbsp;
                            <img
                              src='/images/shockwaveUnlimitedDark.svg'
                              alt='Shockwave Unlimited'
                              width={56}
                              height={19}
                              style={{ width: '56px', height: '19px' }}
                            />
                            &nbsp;members.
                          </a>
                        </Link>
                      </CaptureClickEvent>
                    </p>
                    &nbsp;&nbsp;
                    <CaptureClickEvent
                      action='upsell'
                      location='daily_archive_images'
                      href='/unlimited'
                      properties={() => ({
                        tag_name: 'upsell_archive_full',
                        id: props.game.uid,
                        title: props.game.title,
                        view_node: props.game.href,
                      })}
                    >
                      <ButtonLink 
                        href='/unlimited'
                        secondary
                        small
                        className='--iconRight upsell_archive_full'
                      >
                        View Full Archive
                        <IconFA icon={faCircleRight} />
                      </ButtonLink>
                    </CaptureClickEvent>
                  </>
                )).EndIf()
              }
            </>
          )).EndIf()
        }
      </Slot>
      <DailyArchive 
        gameId={props.game.uid}
        gameTitle={props.game.title}
        slug={props.game.href.split('/').pop()!}
        rows={rows}
        hasPicture={props.game.archive.display === 'picture'}
        displayYear={props.isFullArchive}
        thumbnailUrl={props.game.thumbnailImg}
        isJigsaw={props.game.pageLayout === 'jigsaw'}
        contentUrl={props.game.archive.contentUrl}
        kind={props.kind}
      />
      {
        If(props.hasPagination, () => (
          <Slot name='footer'>
            <Flex container gap0 alignCenter directionColumn>
              <SwPaginator
                useFirstLast
                currentPage={page} 
                numPages={numPages()}
                onPaginate={(_currentPage: number, nextPage: number) => {
                  changePage(nextPage);
                }}
              />
              <div>
                <ButtonLink 
                  href={props.game.href}
                  small
                  secondary
                >
                  Return to Game
                </ButtonLink>
              </div>
            </Flex>
          </Slot>
        )).EndIf()
      }
    </Pod>
  );
});
